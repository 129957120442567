import axiosi from '../axioz';
import global_URL from '../../Const/urls'

const API_URL = `${global_URL}api/auth/`;

export const register = async (username, fname, lname, password, role, email, status) => {
  return await axiosi.post(`${API_URL}register`, { username, fname, lname, password, role, email, status });
};

export const registerUsers = async (username, fname, mname, lname, role, email, phone, address, status, branch) => {
  return await axiosi.post(`${API_URL}registerUsers`, { username, fname, mname, lname, role, email, phone, address, status,branch });
};

export const login = async (username, password) => {
  try {
    const response = await axiosi.post(`${API_URL}login`, { username, password });
    if (response.data.token) {
      localStorage.setItem('authToken', JSON.stringify(response.data));
 
      console.log('Login Response:', response.data.role);
    }
    return response.data;
  } catch (error) {
    console.error('Login Error:', error.response ? error.response.data : error.message);
    throw error; // Rethrow to be caught in the component
  }
};

export const logout = () => {
  localStorage.removeItem('authToken');
};

export const getCurrentUser = () => {
  return JSON.parse(localStorage.getItem('authToken'));
};
