import React from 'react';

// Functional React Component
const headerr = () => {
  return (
    <header className="masthead">
      <div className="container2">
        <div className="masthead-subheading">Nyumba nzuri, Makazi mazuri. Tunathamini ubora wa nyumba yako!</div>
        <div className="masthead-heading text-uppercase">DORCAS COMPANY LIMITED!</div>
        <a className="btn btn-primary btn-xl text-uppercase" href="#portfolio">
         ONA VIFAA VYETU VYOTE
        </a>
      </div>
    </header>
  );
};

export default headerr;
