import React, { useState } from 'react';
import global_URL from '../../../../Const/urls';

const Products = (props) => {
  const [activeProduct, setActiveProduct] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const openModal = (product) => {
    setActiveProduct(product);
    setShowModal(true); 
  };

  const closeModal = () => {
    setActiveProduct(null);
    setShowModal(false);
  };

  return (
    <section className="page-section bg-light" id="products">
      <div className="container2">
        <div className="text-center">
          <h2 className="section-heading text-uppercase">VIFAA VYA UJENZI</h2>
          <h3 className="section-subheading text-muted">Karibu kupata vifaa mbalimbali vya ujenzi imara wa jengo lako.</h3>
        </div>
        <div className="row">
          {props.data && Array.isArray(props.data) && props.data.length > 0 ? (
            props.data.map((item) => (
              <div className="col-lg-4 col-sm-6 mb-4" key={item.id}>
                <div className="portfolio-item">
                  <a
                    className="portfolio-link"
                    // data-bs-toggle="modal"
                    href="#portfolioModal"
                    onClick={() => openModal(item)}
                  >
                    <div className="portfolio-hover">
                      <div className="portfolio-hover-content">
                        <i className="fas fa-plus fa-3x"></i>
                      </div>
                    </div>
                    <img
                      className="img-fluid"
                      src={`${global_URL}${item.image}`}
                      alt={item.pname}
                    />
                  </a>
                  <div className="portfolio-caption">
                    <div className="portfolio-caption-heading">{item.pname}</div>
                    <div className="portfolio-caption-subheading text-muted">{item.pprice}</div>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className="text-center">
              <p>No products available at the moment.</p>
            </div>
          )}
        </div>

        {/* Modal */}
        {showModal && (
          <div
            className="portfolio-modal modal fade show"
            id="portfolioModal"
            tabIndex="-1"
            role="dialog"
            style={{ display: 'block', backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
            aria-hidden="true"
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="close-modal" onClick={closeModal} data-bs-dismiss="modal">
                  <img src="assets/img/close-icon.svg" alt="Close modal" />
                </div>
                <div className="container">
                  <div className="row justify-content-center">
                    <div className="col-lg-8">
                      <div className="modal-body">
                        {/* Project details */}
                        <h2 className="text-uppercase">{activeProduct?.pname}</h2>
                        <p className="item-intro text-muted">{activeProduct?.pprice}</p>
                        <img
                          className="img-fluid d-block mx-auto"
                          src={`${global_URL}${activeProduct.image}`}
                          alt={activeProduct?.pname}
                        />
                        <p>{activeProduct?.pdescription}</p>
                        <ul className="list-inline">
                          <li>
                            <strong>Discount:</strong> {activeProduct?.pdiscount}% OFF
                          </li>
                          <li>
                            <strong>{activeProduct?.pquantity} left</strong> 
                          </li>
                        </ul>
                        <button
                          className="btn btn-primary btn-xl text-uppercase"
                          // onClick={closeModal}
                          type="button"
                        >
                           Order Now
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default Products;
