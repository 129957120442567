import React from 'react';

const Team = () => {
  const teamMembers = [
    {
      id: 1,
      name: 'Parveen Anand',
      role: 'CEO and Founder',
      imgSrc: 'assets/img/team/1.jpg',
      twitterUrl: '#!',
      facebookUrl: '#!',
      linkedInUrl: '#!',
    },
    {
      id: 2,
      name: 'Diana Petersen',
      role: 'Manager',
      imgSrc: 'assets/img/team/2.jpg',
      twitterUrl: '#!',
      facebookUrl: '#!',
      linkedInUrl: '#!',
    },
    {
      id: 3,
      name: 'Larry Parker',
      role: 'Sales Rep',
      imgSrc: 'assets/img/team/3.jpg',
      twitterUrl: '#!',
      facebookUrl: '#!',
      linkedInUrl: '#!',
    },
  ];

  return (
    <section className="page-section bg-light" id="team">
      <div className="container">
        <div className="text-center">
          <h2 className="section-heading text-uppercase">Timu Yetu</h2>
          <h3 className="section-subheading text-muted">Hawa ni watu wetu wa nguvu.</h3>
        </div>
        <div className="row">
          {teamMembers.map((member) => (
            <div className="col-lg-4" key={member.id}>
              <div className="team-member">
                <img className="mx-auto rounded-circle" src={member.imgSrc} alt={member.name} />
                <h4>{member.name}</h4>
                <p className="text-muted">{member.role}</p>
                <a className="btn btn-dark btn-social mx-2" href={member.twitterUrl} aria-label={`${member.name} Twitter Profile`}>
                  <i className="fab fa-twitter"></i>
                </a>
                <a className="btn btn-dark btn-social mx-2" href={member.facebookUrl} aria-label={`${member.name} Facebook Profile`}>
                  <i className="fab fa-facebook-f"></i>
                </a>
                <a className="btn btn-dark btn-social mx-2" href={member.linkedInUrl} aria-label={`${member.name} LinkedIn Profile`}>
                  <i className="fab fa-linkedin-in"></i>
                </a>
              </div>
            </div>
          ))}
        </div>
        <div className="row">
          <div className="col-lg-8 mx-auto text-center">
            <p className="large text-muted">
             Jiunganishe na timu yetu kupitia mitandao ya kijamii.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Team;
