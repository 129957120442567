import React, { useState, useEffect } from 'react';
import { Table, Button, Form, Row, Col, Pagination, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faPencilAlt, faTrash } from '@fortawesome/free-solid-svg-icons';
import axios from '../../../Services/axioz';
import global_URL from '../../../Const/urls';
import ViewProduct from './viewproducts'; // Import the ViewProduct component
import UpdateProduct from './updateproducts'; // Import the UpdateProduct component
import { handleDeleteConfirm } from '../../../Controllers/ProductsController'; // Import the delete handler
import { branch } from '../../../Const/variables';

const GetProducts = () => {
  const [products, setProducts] = useState([]);
  const [filters, setFilters] = useState({
    pname: '',
    pCategoryName: '',
    pcolor: '',
    pprice: '',
    pquantity: '',
  });

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const [showViewModal, setShowViewModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false); // Add state for delete modal
  const [selectedProduct, setSelectedProduct] = useState(null);

  // Fetch products
  const fetchProducts = async () => {
    try {
      const response = await axios.get(`${global_URL}api/getproducts/getProducts/${branch}`);
      setProducts(response.data);
    } catch (error) {
      console.error('Error fetching the products:', error);
    }
  };

  // Initial data fetch
  useEffect(() => {
    fetchProducts();
  }, []);

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters({
      ...filters,
      [name]: value,
    });
    setCurrentPage(1);
  };

  const filteredProducts = products.filter((product) =>
    Object.keys(filters).every((key) =>
      product[key].toString().toLowerCase().includes(filters[key].toLowerCase())
    )
  );

  const indexOfLastProduct = currentPage * itemsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - itemsPerPage;
  const currentProducts = filteredProducts.slice(indexOfFirstProduct, indexOfLastProduct);
  const totalPages = Math.ceil(filteredProducts.length / itemsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleEditClick = (product) => {
    setSelectedProduct(product);
    setShowEditModal(true);
  };

  const handleViewClick = (product) => {
    setSelectedProduct(product);
    setShowViewModal(true);
  };

  const handleDeleteClick = (product) => {
    setSelectedProduct(product);
    setShowDeleteModal(true);
  };

  return (
    <div className="container mt-5 p-4">
      <Row className="align-items-center mb-3">
        <Col>
          <h2 style={{ fontWeight: 'bold' }}>Products</h2>
        </Col>
      </Row>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>#</th>
            <th>
              Product Name
              <Form.Control
                type="text"
                name="pname"
                value={filters.pname}
                onChange={handleFilterChange}
                placeholder="Filter by product name"
              />
            </th>
            <th>
              Category
              <Form.Control
                type="text"
                name="pCategoryName"
                value={filters.pCategoryName}
                onChange={handleFilterChange}
                placeholder="Filter by category"
              />
            </th>
            <th>
              Color
              <Form.Control
                type="text"
                name="pcolor"
                value={filters.pcolor}
                onChange={handleFilterChange}
                placeholder="Filter by color"
              />
            </th>
            <th>
              Price
              <Form.Control
                type="text"
                name="pprice"
                value={filters.pprice}
                onChange={handleFilterChange}
                placeholder="Filter by price"
              />
            </th>
            <th>
              Quantity
              <Form.Control
                type="text"
                name="pquantity"
                value={filters.pquantity}
                onChange={handleFilterChange}
                placeholder="Filter by quantity"
              />
            </th>
            <th>Image</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {currentProducts.length > 0 ? (
            currentProducts.map((product, index) => (
              <tr key={product.id}>
                <td>{indexOfFirstProduct + index + 1}</td>
                <td>{product.pname}</td>
                <td>{product.pCategoryName}</td>
                <td>{product.pcolor}</td>
                <td>{product.pprice}</td>
                <td>{product.pquantity}</td>
                <td>
                  <img
                    src={`${global_URL}${product.image}`}
                    alt={product.pname}
                    style={{ width: '50px', height: '50px' }}
                  />
                </td>
                <td className="d-flex align-items-center">
                  <Button
                    variant="primary"
                    className="me-2"
                    onClick={() => handleViewClick(product)} // Open view modal
                  >
                    <FontAwesomeIcon icon={faEye} />
                  </Button>
                  <Button
                    variant="warning"
                    className="me-2"
                    onClick={() => handleEditClick(product)} // Open edit modal
                  >
                    <FontAwesomeIcon icon={faPencilAlt} />
                  </Button>
                  <Button
                    variant="danger"
                    onClick={() => handleDeleteClick(product)} // Open delete confirmation modal
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </Button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="8" className="text-center">
                Can't find your option, please try again.
              </td>
            </tr>
          )}
        </tbody>
      </Table>

      {/* Pagination Controls */}
      <Pagination className="justify-content-center">
        {[...Array(totalPages)].map((_, pageIndex) => (
          <Pagination.Item
            key={pageIndex + 1}
            active={pageIndex + 1 === currentPage}
            onClick={() => handlePageChange(pageIndex + 1)}
            variant="primary"
            className={pageIndex + 1 === currentPage ? 'primary text-white' : 'primary'}
          >
            {pageIndex + 1}
          </Pagination.Item>
        ))}
      </Pagination>

      {/* View Product Modal */}
      <Modal show={showViewModal} onHide={() => setShowViewModal(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>View Product</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedProduct && <ViewProduct product={selectedProduct} />}
        </Modal.Body>
      </Modal>

      {/* Edit Product Modal */}
      <Modal show={showEditModal} onHide={() => setShowEditModal(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Edit Product</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedProduct && (
            <UpdateProduct
              product={selectedProduct}
              onClose={() => {
                setShowEditModal(false);
                fetchProducts(); // Fetch updated products after closing modal
              }}
            />
          )}
        </Modal.Body>
      </Modal>

      {/* Delete Confirmation Modal */}
      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to delete this product?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
            Cancel
          </Button>
          <Button
            variant="danger"
            onClick={() => handleDeleteConfirm(selectedProduct, products, setProducts, setShowDeleteModal)}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default GetProducts;
