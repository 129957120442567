import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './Views/Client/Home/index';
import CreateSales from './Views/components/Sales/createSales';
import AdminPage from './Views/Admin/Dashboard/dashboard'; // Replace with actual sub-route component
import ManagerPage from './Views/Manager/dashboard';
import StaffPage from './Views/Staff/dashboard';
import Login from './Views/Auth/login';
import Register from './Views/Auth/register';
// import Home from './Views/Client/Home/App';
import PrivateRoute from './Services/Auth/PrivateRoute';
import UpdateProduct from './Views/Manager/products/updateproducts';
import ViewProduct from './Views/Manager/products/viewproducts'
import 'bootstrap/dist/css/bootstrap.min.css';
import './Style/theme.scss';



function App() {
  return (
    <Router>
      <Routes>
      <Route path="/" element={<Home/>} />

      {/* <Route path="/" element={<Home />} /> */}
        <Route path="/login" element={<Login />} />
        <Route path="/create" element={<CreateSales />} />
        <Route path="/register" element={<Register />} />
        <Route path="/update-product/:productId" element={<UpdateProduct />} />
        <Route path="/view-product/:productId" element={<ViewProduct />} />
        <Route path="admin" element={<PrivateRoute element={AdminPage} allowedRoles={['admin']} />}>
          {/* <Route path="sales" element={<PrivateRoute element={CreateSales} allowedRoles={['admin']} />} />
          <Route path="products" element={<PrivateRoute element={CreateSales} allowedRoles={['admin']} />} />
          <Route path="inventories" element={<PrivateRoute element={CreateSales} allowedRoles={['admin']} />} /> */}
          {/* Add other sub-routes as needed */}
        </Route>
        <Route path="/manager" element={<PrivateRoute element={ManagerPage} allowedRoles={['manager']} />} />
        <Route path="/staff" element={<PrivateRoute element={StaffPage} allowedRoles={['staff']} />} />
      </Routes>
    </Router>
  );
}

export default App;
