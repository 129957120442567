import React from 'react';
import Sidebar from './sidebar';
// import 'bootstrap/dist/css/bootstrap.min.css';

function ManagerHome() {
  return (
    <div>
      <Sidebar/>
    </div>
  );
}

export default ManagerHome;