import React from 'react';

const Clients = () => {
  const logos = [
    {
      id: 1,
      imgSrc: 'assets/img/logos/Simba-Cement-Logo.png',
      alt: 'Simba-Cement Logo',
      ariaLabel: 'Simba-Cement Logo',
      href: '#!',
    },
    {
      id: 2,
      imgSrc: 'assets/img/logos/CORAL.png',
      alt: 'Coral Logo',
      ariaLabel: 'Coral Logo',
      href: '#!',
    },
    {
      id: 3,
      imgSrc: 'assets/img/logos/national-gypsum-logo.png',
      alt: 'National-gypsum Logo',
      ariaLabel: 'National-gypsum Logo',
      href: '#!',
    },
    {
      id: 4,
      imgSrc: 'assets/img/logos/plascone.png',
      alt: 'Plascone Logo',
      ariaLabel: 'Plascone Logo',
      href: '#!',
    },
  ];

  return (
    <div className="py-5">
      <div className="container2">
        <div className="row align-items-center">
          {logos.map((logo) => (
            <div className="col-md-3 col-sm-6 my-3" key={logo.id}>
              <a href={logo.href}>
                <img
                  className="img-fluid img-brand d-block mx-auto"
                  src={logo.imgSrc}
                  alt={logo.alt}
                  aria-label={logo.ariaLabel}
                />
              </a>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Clients;
