import React, { useState } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
// import 'bootstrap/dist/css/bootstrap.min.css';
import logo from '../../Assets/LOGO-PNG-01.png';
import { login } from '../../Services/Auth/authService'; // Import the login function

const LoginForm = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState('');
    // const history = useHistory();

    const handleLogin = async (e) => {
        e.preventDefault();
    
        try {
            const response = await login(username, password);
            setMessage('Login successful!');
    
            const userRole = response.role; // Assuming the response contains the user's role
            localStorage.setItem('username', JSON.stringify(response.uname));
            localStorage.setItem('role', JSON.stringify(response.role));
            localStorage.setItem('branch', JSON.stringify(response.branch));

            switch (userRole) {
                case 'admin':
                    window.location = "/admin";
                    break;
                case 'manager':
                    window.location = "/manager";
                    break;
                case 'staff':
                    window.location = "/staff";
                    break;
                default:
                    window.location = "/"; // Redirect to home or any default page
                    break;
            }
        } catch (error) {
            console.log(error);
            setMessage('Invalid credentials');
        }
    };
    

    return (
        <Container fluid className="d-flex justify-content-center align-items-center min-vh-100 bg-primary">
            <Row className="w-70 shadow-lg bg-white rounded">
                <Col md={6} className="d-flex align-items-center justify-content-center">
                <img
          src={logo}
          alt="Dorcas Company LTD"
          className="img-fluid p-3"
          style={{ maxHeight: '300px', maxWidth: '300px', objectFit: 'contain' }}
        />
                </Col>
                <Col md={6} className="p-5">
                    <h2 className="text-center mb-4">Welcome Back!</h2>
                    <Form onSubmit={handleLogin}>
                        <Form.Group controlId="formUsername" className="mb-3">
                            <Form.Label className="text-start w-200">Username</Form.Label>
                            <Form.Control 
                                type="text" 
                                placeholder="Enter Username" 
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                                className="rounded-pill"
                            />
                        </Form.Group>

                        <Form.Group controlId="formPassword" className="mb-3">
                            <Form.Label className="text-start w-200">Password</Form.Label>
                            <Form.Control 
                                type="password" 
                                placeholder="Enter Password" 
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                className="rounded-pill"
                            />
                        </Form.Group>

                        <Form.Group controlId="formBasicCheckbox" className="mb-3 d-flex justify-content-start">
                            <Form.Check type="checkbox" label="Remember Me" />
                        </Form.Group>

                        <Button variant="primary" type="submit" className="w-100">
                            Login
                        </Button>

                        <div className="text-center mt-3">
                            <a href="#forgot-password" className="text-muted">Forgot Password?</a>
                        </div>

                        {message && (
                            <div className="text-center mt-3">
                                <p>{message}</p>
                            </div>
                        )}
                    </Form>
                </Col>
            </Row>
        </Container>
    );
}

export default LoginForm;
