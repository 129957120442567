import React, { useState } from 'react';
import Nav from 'react-bootstrap/Nav';
import { Accordion, Dropdown } from 'react-bootstrap';
import { FaUser, FaPlus, FaUsers, FaChevronDown, FaChevronUp, FaBox, FaTachometerAlt } from 'react-icons/fa';
import logo from '../../Assets/LOGO-PNG-01.png';
import { useNavigate } from 'react-router-dom';
import { logout } from '../../Services/Auth/authService';
import GetSales from './Sales/getSales';
import CreateSales from './Sales/addSales';
import GetProduct from '../Manager/products/getProducts';
import GetInventories from '../Manager/inventories/getinventories';
import {branch} from '../../Const/variables';

const StaffSidebar = () => {
  const [isCollapsed] = useState(false);
  const [isSalesOpen, setIsSalesOpen] = useState(false);
  const [activeComponent, setActiveComponent] = useState('');
  const [activeLink, setActiveLink] = useState('dashboard'); // Default active link
  const navigate = useNavigate();
  const username = JSON.parse(localStorage.getItem('username'));
  const role = JSON.parse(localStorage.getItem('role'));

  const navigateTo = (path) => {
    navigate(path);
  };

  const toggleSalesSection = () => {
    setIsSalesOpen(!isSalesOpen);
  };

  
  const handleComponentChange = (componentName, linkName) => {
    setActiveComponent(componentName);
    setActiveLink(linkName); // Set the active link
  };

  const renderComponent = () => {
    switch (activeComponent) {
      case 'all-sales':
        return <GetSales />;
      case 'create-sales':
        return <CreateSales />;
      case 'all-products':
        return <GetProduct />;
      case 'inventories':
        return <GetInventories />;
      default:
        return <div>Select an option from the sidebar</div>;
    }
  };

  return (
    <div style={{ display: 'flex', height: '100vh' }}>
      <div
        className={`bg-primary text-white vh-100 d-flex flex-column p-2 ${isCollapsed ? 'collapsed' : ''}`}
        style={{ width: isCollapsed ? '110px' : '250px', transition: 'width 0.3s', position: 'relative' }}
      >
        <img
          src={logo}
          alt="Dorcas Company LTD"
          className="img-fluid p-3"
          style={{ maxHeight: '100px', maxWidth: '100px', objectFit: 'contain' }}
        />
        {!isCollapsed && (
          <div className="d-flex justify-content-center mb-3">
            <h5 style={{ fontWeight: 'bold' }}>DORCAS COMPANY LTD</h5>
          </div>
        )}
        <Nav defaultActiveKey="/dashboard" className="flex-column flex-grow-1">
          <Nav.Link
            className={`d-flex ${isCollapsed ? 'flex-column align-items-center justify-content-center' : 'align-items-center justify-content-start'} ${activeLink === 'dashboard' ? 'text-primary bg-white rounded' : 'text-white'} `}
            onClick={() => handleComponentChange('dashboard', 'dashboard')}
          >
            <FaTachometerAlt size={isCollapsed ? '24px' : '20px'} />
            {!isCollapsed && <span style={{ fontWeight: 'bold' }} className="ms-2">Dashboard</span>}
            {isCollapsed && <span style={{ fontWeight: 'bold' }} className="mt-1">Dashboard</span>}
          </Nav.Link>

                    {/* Sales Section */}
                    <div className="d-flex flex-column">
            <Nav.Link
              className={`text-white d-flex ${isCollapsed ? 'flex-column align-items-center justify-content-center' : 'align-items-center justify-content-start'} ${activeLink === 'sales' ? 'text-primary bg-white rounded' : 'text-white'}`}
              onClick={toggleSalesSection}
              aria-controls="sales-collapse"
              aria-expanded={isSalesOpen}
              style={{ position: 'relative' }}
            >
              <div className={`d-flex ${isCollapsed ? 'flex-column align-items-center' : 'align-items-center justify-content-between'}`} style={{ width: '100%' }}>
                <div className="d-flex align-items-center">
                  <FaUsers size={isCollapsed ? '24px' : '20px'} />
                  {!isCollapsed && <span style={{ fontWeight: 'bold' }} className="ms-2">Sales</span>}
                  {isCollapsed && <span style={{ fontWeight: 'bold' }} className="mt-1">Sales</span>}
                </div>
                <span className="d-flex align-items-center">
                  {isSalesOpen ? <FaChevronUp size="16px" /> : <FaChevronDown size="16px" />}
                </span>
              </div>
            </Nav.Link>
            <Accordion activeKey={isSalesOpen ? '0' : null}>
              <Accordion.Collapse eventKey="0">
                <div id="products-collapse" className={`ms-${isCollapsed ? '3' : '4'}`}>
                  <Nav.Link onClick={() => handleComponentChange('all-sales', 'all-sales')} className={`${activeLink === 'all-sales' ? 'text-primary bg-white rounded' : 'text-white'}`}>
                    <FaUser size="16px" className="me-2" />
                    {!isCollapsed && <span>All Sales</span>}
                  </Nav.Link>
                  <Nav.Link onClick={() => handleComponentChange('create-sales', 'create-sales')} className={`${activeLink === 'create-sales' ? 'text-primary bg-white rounded' : 'text-white'}`}>
                    <FaPlus size="16px" className="me-2" />
                    {!isCollapsed && <span>Perform Sales</span>}
                  </Nav.Link>
                </div>
              </Accordion.Collapse>
            </Accordion>
          </div> 

          {/* Products Section */}
          <Nav.Link 
            className={`d-flex ${isCollapsed ? 'flex-column align-items-center justify-content-center' : 'align-items-center justify-content-start'} ${activeLink === 'all-products' ? 'text-primary bg-white rounded' : 'text-white'}`}
            onClick={() => handleComponentChange('all-products', 'all-products')}
          >
            <FaBox size={isCollapsed ? '24px' : '20px'} />
            {!isCollapsed && <span style={{ fontWeight: 'bold' }} className="ms-2">Products</span>}
            {isCollapsed && <span style={{ fontWeight: 'bold' }} className="mt-1">Products</span>}
          </Nav.Link>

          {/* Inventory Section */}
          <Nav.Link 
            className={`d-flex ${isCollapsed ? 'flex-column align-items-center justify-content-center' : 'align-items-center justify-content-start'} ${activeLink === 'inventories' ? 'text-primary bg-white rounded' : 'text-white'}`}
            onClick={() => handleComponentChange('inventories', 'inventories')}
          >
            <FaBox size={isCollapsed ? '24px' : '20px'} />
            {!isCollapsed && <span style={{ fontWeight: 'bold' }} className="ms-2">Inventories</span>}
            {isCollapsed && <span style={{ fontWeight: 'bold' }} className="mt-1">Inventories</span>}
          </Nav.Link>
        </Nav>

        <Dropdown.Divider />

        <Dropdown>
          

          <div className="d-flex justify-content-center align-items-center">
  <Dropdown.Toggle
    variant="link"
    id="dropdown-basic"
    className="text-decoration-none text-center"
    style={{ color: 'white' }}
  >
    Ndugu: {username} : {role}
  </Dropdown.Toggle>
</div>


          <Dropdown.Menu align="end">
            <Dropdown.Item onClick={() => navigateTo('/profile')}>Profile</Dropdown.Item>
            <Dropdown.Item onClick={() => navigateTo('/change-password')}>Change Password</Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item onClick={() => {
              logout();
              navigateTo('/login');
            }}>
              Logout
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>

        <div className="d-flex justify-content-center align-items-center">
   {branch} Branch
  </div>
      </div>

      <div className="flex-grow-1">
        {renderComponent()}
      </div>
    </div>
  );
};

export default StaffSidebar;
