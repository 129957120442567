import axiosi from '../Services/axioz';
import global_URL from '../Const/urls';

const API_URL_add = `${global_URL}api/addproducts/`;

const API_URL_update = `${global_URL}api/updateproducts/`;

const API_URL_delete = `${global_URL}api/deleteproducts/`;

// ADDING 
export const addProducts = async (formData) => {
  // Notice we're not converting the formData to JSON
  return await axiosi.post(`${API_URL_add}addProducts`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

// UPDATING 
export const updateProduct = async (id, formData) => {
  try{
    await axiosi.put(`${API_URL_update}updateProduct/${id}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }catch(error){
    console.error('Error updating product:', error);
  }
};

// DETEING 

export const handleDeleteConfirm = async (selectedProduct, products, setProducts, setShowDeleteModal) => {
  try {
    await axiosi.delete(`${API_URL_delete}deleteProduct/${selectedProduct.id}`);
    setProducts(products.filter((product) => product.id !== selectedProduct.id)); // Update products list
    setShowDeleteModal(false); // Close modal after deletion
  } catch (error) {
    console.error('Error deleting product:', error);
  }
};


