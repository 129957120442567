// CreateSales.js
import React from 'react';
import { Form, Button } from 'react-bootstrap';

const CreateSales = () => {
  return (
    <div className="container my-5">
      <h2>Create Sales</h2>
      <Form>
        <Form.Group controlId="formProductName" className="mb-3">
          <Form.Label>Product Name</Form.Label>
          <Form.Control as="select">
            <option>Select Product</option>
            {/* Add product options here */}
          </Form.Control>
        </Form.Group>

        <Form.Group controlId="formQuantity" className="mb-3">
          <Form.Label>Quantity</Form.Label>
          <Form.Control type="number" placeholder="Enter quantity" />
        </Form.Group>

        <Button variant="success" type="submit">
          Save
        </Button>
      </Form>
    </div>
  );
};

export default CreateSales;
