import React, { useState } from 'react';
import { Form, Button, Container, Row, Col, Card } from 'react-bootstrap';
import { register } from '../../Services/Auth/authService';

function Register() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [fname, setFname] = useState('');
  const [lname, setLname] = useState('');
  // const [role, setRole] = useState('');
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [touched, setTouched] = useState({});

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await register(username, fname, lname, password, "admin", email, 1);
      window.location = "/login";
    } catch (err) {
      setError('Registration failed');
    }
  };

  const handleBlur = (field) => () => {
    setTouched({ ...touched, [field]: true });
  };

  const getFieldClass = (field) => {
    const fieldValues = { username, password, fname, lname, email };

    if (field === 'email') {
      return touched.email && !validateEmail(email) ? 'is-invalid' : '';
    }
    return touched[field] && !fieldValues[field] ? 'is-invalid' : '';
  };

  const validateEmail = (email) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };

  return (
    <Container fluid className="d-flex min-vh-100">
      <Row className="justify-content-center align-items-center w-100">
        <Col xs={12} md={8} lg={6}>
          <Card className="shadow-lg p-3">
            <Card.Body>
              <Card.Title className="text-center mb-4">Register</Card.Title>
              <Form onSubmit={handleSubmit}>
                <Form.Group controlId="formUsername" className="mb-3">
                  <Form.Label>Username</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    onBlur={handleBlur('username')}
                    className={`w-100 ${getFieldClass('username')}`}
                    required
                  />
                  {touched.username && !username && <Form.Text className="text-danger">Username is required</Form.Text>}
                </Form.Group>

                <Form.Group controlId="formFname" className="mb-3">
                  <Form.Label>First Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter first name"
                    value={fname}
                    onChange={(e) => setFname(e.target.value)}
                    onBlur={handleBlur('fname')}
                    className={`w-100 ${getFieldClass('fname')}`}
                    required
                  />
                  {touched.fname && !fname && <Form.Text className="text-danger">First Name is required</Form.Text>}
                </Form.Group>

                <Form.Group controlId="formLname" className="mb-3">
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter last name"
                    value={lname}
                    onChange={(e) => setLname(e.target.value)}
                    onBlur={handleBlur('lname')}
                    className={`w-100 ${getFieldClass('lname')}`}
                    required
                  />
                  {touched.lname && !lname && <Form.Text className="text-danger">Last Name is required</Form.Text>}
                </Form.Group>
{/* 
                <Form.Group controlId="formRole" className="mb-3">
                  <Form.Label>Role</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter role"
                    value={role}
                    onChange={(e) => setRole(e.target.value)}
                    onBlur={handleBlur('role')}
                    className={`w-100 ${getFieldClass('role')}`}
                    required
                  />
                  {touched.role && !role && <Form.Text className="text-danger">Role is required</Form.Text>}
                </Form.Group> */}

                <Form.Group controlId="formEmail" className="mb-3">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    onBlur={handleBlur('email')}
                    className={`w-100 ${getFieldClass('email')}`}
                    required
                  />
                  {touched.email && !validateEmail(email) && (
                    <Form.Text className="text-danger">Please enter a valid email address</Form.Text>
                  )}
                </Form.Group>

                <Form.Group controlId="formPassword" className="mb-3">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Enter password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    onBlur={handleBlur('password')}
                    className={`w-100 ${getFieldClass('password')}`}
                    required
                  />
                  {touched.password && !password && <Form.Text className="text-danger">Password is required</Form.Text>}
                </Form.Group>

                <Button variant="primary" type="submit" className="w-100">
                  Register
                </Button>
                {error && <p className="text-danger mt-3">{error}</p>}
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default Register;
