import React from 'react';

const ContactSection = () => {
  return (
    <section className="page-section" id="contact">
      <div className="container2">
        <div className="text-center">
          <h2 className="section-heading text-uppercase">Wasiliana Nasi</h2>
          <h3 className="section-subheading text-muted">Tunaomba jina lako,namba na barua pepe, ili kukupata.</h3>
        </div>
        <form id="contactForm" >
          <div className="row align-items-stretch mb-5">
            <div className="col-md-6">
              <div className="form-group">
                {/* Name input */}
                <input
                  className="form-control"
                  id="name"
                  type="text"
                  placeholder="Jina lako *"
                  data-sb-validations="required"
                />
                <div className="invalid-feedback" data-sb-feedback="name:required">
                  A name is required.
                </div>
              </div>
              <div className="form-group">
                {/* Email address input */}
                <input
                  className="form-control"
                  id="email"
                  type="email"
                  placeholder="Barua pepe *"
                  data-sb-validations="required,email"
                />
                <div className="invalid-feedback" data-sb-feedback="email:required">
                  An email is required.
                </div>
                <div className="invalid-feedback" data-sb-feedback="email:email">
                  Email is not valid.
                </div>
              </div>
              <div className="form-group mb-md-0">
                {/* Phone number input */}
                <input
                  className="form-control"
                  id="phone"
                  type="tel"
                  placeholder="Namba yako *"
                  data-sb-validations="required"
                />
                <div className="invalid-feedback" data-sb-feedback="phone:required">
                  A phone number is required.
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group form-group-textarea mb-md-0">
                {/* Message input */}
                <textarea
                  className="form-control"
                  id="message"
                  placeholder="Ujumbe wako *"
                  data-sb-validations="required"
                ></textarea>
                <div className="invalid-feedback" data-sb-feedback="message:required">
                  A message is required.
                </div>
              </div>
            </div>
          </div>
          <div className="d-none" id="submitSuccessMessage">
            <div className="text-center text-white mb-3">
              <div className="fw-bolder">Form submission successful!</div>
              To activate this form, sign up at
              <br />
              <a href="https://startbootstrap.com/solution/contact-forms">
                https://startbootstrap.com/solution/contact-forms
              </a>
            </div>
          </div>
          <div className="d-none" id="submitErrorMessage">
            <div className="text-center text-danger mb-3">Error sending message!</div>
          </div>
          {/* Submit Button */}
          <div className="text-center">
            <button className="btn btn-primary btn-xl text-uppercase disabled" id="submitButton" type="submit">
              Tuma jumbe
            </button>
          </div>
        </form>
      </div>
    </section>
  );
};

export default ContactSection;