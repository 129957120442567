import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';

const isAuthenticated = () => {
  return !!localStorage.getItem('authToken');
};

const getUserRole = () => {
  return JSON.parse(localStorage.getItem('role'));
};

const PrivateRoute = ({ element: Component, allowedRoles }) => {
  const [inactive, setInactive] = useState(false);

  useEffect(() => {
    let timeout;

    const resetTimer = () => {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        setInactive(true); // Set inactive to true after 20 minutes
        localStorage.removeItem('authToken');
      }, 1200000); // 20 minutes
    };

    // Reset the timer on user interactions
    window.addEventListener('mousemove', resetTimer);
    window.addEventListener('keydown', resetTimer);
    
    // Initial timer set
    resetTimer();

    // Cleanup event listeners on component unmount
    return () => {
      clearTimeout(timeout);
      window.removeEventListener('mousemove', resetTimer);
      window.removeEventListener('keydown', resetTimer);
    };
  }, []);

  const userRole = getUserRole();

  // Redirect to login page if inactive
  if (inactive) {
    return <Navigate to="/login" />;
  }

  // Check if the user is authenticated and has the appropriate role
  if (isAuthenticated() && allowedRoles.includes(userRole)) {
    return <Component />;
  } else {
    return <Navigate to="/login" />;
  }
};

export default PrivateRoute;
