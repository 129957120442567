import React from 'react';
import Sidebar from '../sidebar';
// import CreateSales from '../components/Sales/createSales';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import { Outlet } from 'react-router-dom';

function AdminHome() {
  return (
    <div>
      <Sidebar />
      {/* <div className="flex-grow-1">
        <Header />
        <h1>Hello admin</h1>
      </div> */}

      {/* <Outlet/> */}
    </div>
  );
}

export default AdminHome;