import React, { useState, useEffect } from 'react';
import { Form, Button, Container, Row, Col, Card } from 'react-bootstrap';
import { addSale } from '../../../Controllers/SalesController';
import global_URL from '../../../Const/urls';
import axioz from '../../../Services/axioz';
import {username,branch} from '../../../Const/variables';

function CreateSale() {
  const [pid, setPId] = useState('');
  const [quantity, setQuantity] = useState(''); 
  const [productOptions, setProductOptions] = useState([]);
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const [touched, setTouched] = useState({});

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();

    formData.append('pid', pid);
    formData.append('quantity', quantity);
    formData.append('soldBy', username);
    formData.append('status', 1);

    try {
      const response = await addSale(formData);

      // Check if the response is successful
      if (response.status === 200) {
        setMessage('Sale added successfully');
        
        // Reset form fields
        setPId('');
        setQuantity('');

        // Clear any existing error
        setError('');
      } else {
        setError('Failed to add sale');
      }
    } catch (err) {
      setError('Adding sale failed');
    }
  };

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const res = await axioz.get(`${global_URL}api/getproducts/getProducts/${branch}`);
        setProductOptions(res.data); // Assuming response.data contains the categories\
        } catch (err) {
        console.error('Error fetching the products', err);
      }
    };

    fetchProducts();
  }, []);

  const handleBlur = (field) => () => {
    setTouched({ ...touched, [field]: true });
    setMessage('');
  };

  const getFieldClass = (field) => {
    const fieldValues = { pid, quantity }; // Changed from password to phone
    return touched[field] && !fieldValues[field] ? 'is-invalid' : '';
  };


  return (
    <Container fluid className="d-flex min-vh-100">
      <Row className="justify-content-center align-items-center w-100">
        <Col xs={12} md={8} lg={6} className="px-4 py-2">
          <Card className="shadow-lg p-3">
            <Card.Body>
              <Card.Title className="text-center mb-4">Perform Sale</Card.Title>
              <Form onSubmit={handleSubmit}>
              <Form.Group controlId="formProduct" className="mb-3">
                  <Form.Label>Sale</Form.Label>
                  <Form.Control
                    as="select"
                    value={pid}
                    onChange={(e) => setPId(e.target.value)}
                    onBlur={handleBlur('pid')}
                    className={`w-100 ${getFieldClass('pid')}`}
                    required
                  >
                    <option value="">Select product</option>
                        {productOptions.map((val) => (
                          <option key={val.id} value={val.id}>
                            {val.pname}
                          </option>
                        ))}
                  </Form.Control>
                  {touched.pid && !pid && (
                    <Form.Text className="text-danger">Product is required</Form.Text>
                  )}
                </Form.Group>

                <Form.Group controlId="formQuantity" className="mb-3">
                  <Form.Label>Quantity</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Enter quantity"
                    value={quantity}
                    onChange={(e) => setQuantity(e.target.value)}
                    onBlur={handleBlur('quantity')}
                    className={`w-100 ${getFieldClass('quantity')}`}
                    required
                  />
                  {touched.quantity && !quantity && <Form.Text className="text-danger">Quantity is required</Form.Text>}
                </Form.Group>

                <Button variant="primary" type="submit" className="w-100">
                  Save
                </Button>

                {message && (
                  <div className="text-center mt-3">
                    <p className="text-success">{message}</p>
                  </div>
                )}

                {error && <p className="text-danger mt-3">{error}</p>}
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default CreateSale;
